import * as React from 'react'
import PricingBanner from '../components/PricingBanner'
import Layout from '../components/Layout'
import Seo from "../components/Seo"

const TermsPage = () => {
    return (
        <Layout>
            <Seo 
                title="Terms of Service"
                description="Terms and Conditions "
            />
            <div className='flex items-center justify-center'>
                <h1 className="my-8 text-4xl font-bold text-center md:my-12 text-rickshaw-blue">
                    Terms of Service
                </h1>
            </div>

            <PricingBanner />
        </Layout>
    );
};

export default TermsPage;
